import ProductItem from "../Items/ProductItem";

export default function ProductList({ products, coupons, handleCoupons, currPage }) {
    console.log(coupons);
    return <div className="mc-product-list">
        <div>
            {coupons && (coupons?.rows || coupons).map((item, index) => {
                return <ProductItem
                    coupon={item}
                    index={index}
                    handleCoupons={handleCoupons}
                    currPage={currPage}
                />
            })}
        </div>
    </div>
}