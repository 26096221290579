

export const getCouponData = async () => {
    try {
        // const response = await axios.post("/api")
        return {
            success: true, data: {
                rows: [
                    { name: "paras", id: 1 }
                ]
            }
        };
    } 
    catch (error) {
        return error.message;
    }
}