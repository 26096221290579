import {React, createContext, useState} from 'react'

export const Page = createContext();

function Pages({children}) {
    const [currentPage, setCurrentPage] = useState(1);
  return (<Page.Provider value={{ currentPage, setCurrentPage }}>
   {children}
</Page.Provider>
  )
}

export default Pages



